/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import "./index.scss";
import { setTemplate } from "../../../../actions/templates";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Icon from "../../../common/Icon";
import EditorLeft from "../editor/editorComponents/editorLeft";
import { toast } from "react-toastify";
import axios from "axios";
import Input from "../../../common/Inputs";
import { tools } from "../newWritingTools";
import { v4 as uuidv4 } from "uuid";
import { Editor } from "@tinymce/tinymce-react";
import Loader from "../../../common/Loader";
import AiItem from "../editor/editorComponents/AiItem";
import { getDoc, setDoc, setId } from "../../../../actions/document";

// tiny
import "tinymce/plugins/advlist";
import "tinymce/plugins/autolink";
import "tinymce/plugins/link";
import "tinymce/plugins/image";
import "tinymce/plugins/lists";
import "tinymce/plugins/charmap";
import "tinymce/plugins/anchor";
import "tinymce/plugins/searchreplace";
import "tinymce/plugins/wordcount";
import "tinymce/plugins/code";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/insertdatetime";
import "tinymce/plugins/nonbreaking";
import "tinymce/plugins/table";
import "tinymce/plugins/template";
import "tinymce/plugins/help";
import { isEqual } from "lodash";

const NewDocumentEditor = ({
  template,
  clearDoc,
  clearTemplate,
  clearId,
  document,
  changeTemplate,
  auth: { user },
  id,
  getDoc,
  loading
}) => {
  const [mainTools, setMainTools] = useState(tools);
  const history = useHistory();
  const [search, setSearch] = useState("");
  const BarRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingTemplate, setIsLoadingTemplate] = useState(true);
  const isEdited = useRef(false);
  const [isOpenExpireModal, setIsOpenExpireModal] = useState(false);
  const [inputs, setInputs] = useState({
    name: document.productName || "",
    content: document.productContent || "",
    voice: "",
    language: user && user.language ? user.language : "English",
    amount: 1,
    size: "S",
  });
  const [prevContent, setPrevContent] = useState({
    name: document.productName || "",
    content: document.productContent || "",
    voice: "",
    language: user && user.language ? user.language : "English",
    amount: 1,
    size: "S",
  });
  const [description, setDescription] = useState(
    document ? document.content : ""
  );

  const [productName, setProductName] = useState("");
  useEffect(() => {
    if (id) {
      getDoc(id);
    }
  }, []);
  useEffect(() => {
    if (document && document._id) {
      setInputs({
        name: document.productName || "",
        content: document.productContent || "",
        voice: "",
        language: user && user.language ? user.language : "English",
        amount: 1,
        size: "S",
      });
      setPrevContent({
        name: document.productName || "",
        content: document.productContent || "",
        voice: "",
        language: user && user.language ? user.language : "English",
        amount: 1,
        size: "S",
      });
    }
  }, [document]);
  const handleSave = async (data, isHaveData,desc) => {
    if (id) {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const body = JSON.stringify({
          content: isHaveData ?  description : desc,
          title: "Custom Document",
          name: isHaveData
            ? productName || data.name
            : productName || inputs.name,
          productName: isHaveData ? data.name : inputs.name,
          productContent: isHaveData ? data.content : inputs.content,
        });
        const res = await axios.patch(
          `/api/documents/findone/${id}`,
          body,
          config
        );
      } catch (err) {
        console.log(err.message);
      }
    }
  };
  const handleSubmit = async () => {
    if (!inputs.content || !inputs.content.trim()) {
      toast.error("Please fill required fields");
      return;
    }
    let titleP = inputs.name.length ? inputs.name + "," : "";
    let toneP = inputs.voice.length
      ? `. Tone of voice is ${inputs.voice}.`
      : "";
    let lang = inputs.language.length
      ? `The output language is: ${inputs.language}.`
      : "";
    let apiPrompt = `${template.prompt} ${titleP}${inputs.content.trim()}${toneP} ${lang}`;
    setIsLoading(true);
    try {
      let documentTokens = 100;
      if (inputs.size === "M") {
        documentTokens = 200;
      } else if (inputs.size === "L") {
        documentTokens = 300;
      }
      let finishabelText = apiPrompt;
      if (isEdited.current && description.length) {
        finishabelText = description;
      }
      if (!isEqual(prevContent, inputs)) {
        finishabelText = apiPrompt;
        setPrevContent(inputs);
      }
      const promise = await axios.post("/api/index/beastmode", {
        text: finishabelText.replace(/(<([^>]+)>)/gi, ""),
        engine: template.engine,
        token: documentTokens,
        runs: 1,
        title: inputs.name,
      });
      const list = promise.data["choices"];

      const connectText = list.reduce(
        (prev, next) => prev + next.text.replaceAll(/[\r\n]/gm, ""),
        description || ""
      );
      let text = "";
      if (connectText[connectText.length - 1] === ".") {
        text = connectText;
      } else {
        text = connectText;
      }
      setProductName(promise.data?.title);
      setDescription(text);
    } catch (err) {
      setIsLoading(false);
      if (
        err &&
        err.response &&
        err.response.data &&
        err.response.data.raw &&
        err.response.data.raw.message
      ) {
        if (err.response.data.raw.message === "You can not generate document") {
          setIsOpenExpireModal(true);
          return;
        }
        toast.error(err.response.data.raw.message);
        return;
      }
      toast.error("Something went wrong. Try again.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setInputs({
      name: "",
      content: "",
      voice: "",
      language: user && user.language ? user.language : "English",
      amount: 1,
      size: "S",
    });
  }, [user]);
  useEffect(() => {
    if (!template.icon) {
      GoBack();
    } else {
      setIsLoadingTemplate(false);
    }
  }, []);
  const GoBack = () => {
    history.goBack();
    clearDoc();
    clearId();
    clearTemplate();
  };
  const RefreshAndChangeTemplate = (newTemplate) => {
    changeTemplate(newTemplate);
    BarRef.current.style.right = "-100%";
  };
  const handleInputChange = (name, value) => {
    setInputs({
      ...inputs,
      [name]: value,
    });
    handleSave(
      {
        ...inputs,
        [name]: value,
      },
      true,
      description
    );
  };    
  const cleanButton = () => {
    setInputs({
      name: "",
      content: "",
      voice: "",
      language: user.language ? user.language : "English",
      amount: 1,
      size: "S",
    });
    setDescription("");
  };
  const handleEditorChangeValue = (value) => {
    // let editorText = "";
    // if (type === "keyUp") {
    //   editorText = evt.target.innerHTML;
    // } else {
    //   editorText = evt.target.getContent();
    // }\
      handleSave(inputs,true,value);
    isEdited.current = true;
    setDescription(value);
  };
  useEffect(() => {
    if (search.length) {
      setMainTools(
        tools.filter((e) =>
          e.title.toLowerCase().includes(search.toLowerCase())
        )
      );
    } else {
      setMainTools(tools);
    }
  }, [search]);
  return (
    <div className="ai__editor">
      {(isLoading || isLoadingTemplate || loading) && <Loader />}
      {!isLoadingTemplate && !loading && (
        <div className="wrapper">
          <div className="ai__back" role="presentation" onClick={GoBack}>
            <Icon name="ArrowBack" />
            <span>Back</span>
          </div>
          <div className="editor__header">
            <div className="editor__header__left">
              <div className="editor__header__left__text">
                <div className="title">New Document</div>
                <div className="description">
                  Create your own copy from scratch using our AI generator
                </div>
              </div>
            </div>
            <div className="editor__header__right">
              {/*  to do */}
              {/* <div
              className="bar__button"
              role="presentation"
              onClick={() => (BarRef.current.style.right = "0%")}
            >
              <Icon name="DoubleArrowLeft" />
              <span>Templates</span>
            </div> */}
              <div className="editor__hide__bar" ref={BarRef}>
                <div className="editor__hide__bar__wrapper">
                  <Input
                    iconName="Search"
                    value={search}
                    onChange={(name, value) => setSearch(value)}
                  />
                  <div
                    className="editor__hide__bar__button"
                    role="presentation"
                    onClick={() => (BarRef.current.style.right = "-100%")}
                  >
                    <Icon name="DoubleArrowLeft" />
                  </div>
                  <div className="editor__hide__bar__items">
                    {mainTools &&
                      mainTools.map((tool) => {
                        return (
                          <div
                            key={uuidv4()}
                            className="editor__hide__bar__item"
                            role="presentation"
                            onClick={() => RefreshAndChangeTemplate(tool)}
                          >
                            <div className="icon">
                              <Icon name={tool.icon} />
                            </div>
                            <div className="title">{tool.title}</div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="editor__content editor__content__mini">
            <EditorLeft
              cleanButton={cleanButton}
              handleSubmit={handleSubmit}
              template={template}
              inputs={inputs}
              newDocument={true}
              user={user}
              isOpenExpireModal={isOpenExpireModal}
              setIsOpenExpireModal={setIsOpenExpireModal}
              isLoading={isLoading}
              handleInputChange={handleInputChange}
            />
            <div className="editor__right">
              <Editor
                apiKey="4zftpglo0xilvkh7i4fsutgd3xuk4g4qax638o61jje1gexo"
                initialValue={document ? document.content : ""}
                onEditorChange={handleEditorChangeValue}
                value={description}
                rollback={true}
                // onKeyUp={(e) => handleEditorChangeValue(e, "keyUp")}
                init={{
                  selector: "textarea",
                  placeholder: "Begin writing on your own...",
                  height: 500,
                  menubar: "tools",
                  contextmenu:
                    "copy  paste  link image inserttable | cell row column deletetable",
                  plugins: "link code contextmenu",
                  toolbar:
                    "h1 h2 h3 h4 h5 formatselect " +
                    "bold italic underline strikethrough code alignleft aligncenter " +
                    "alignright alignjustify link blockquote undo redo",

                  content_style:
                    "body { height:100%; font-size: 18px } " +
                    "p {margin: 0px}",
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

NewDocumentEditor.propTypes = {
  changeTemplate: PropTypes.func,
  getDoc: PropTypes.func,
  id: PropTypes.any,
  loading: PropTypes.bool,
  document: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    template: state.template,
    document: state.document.document,
    id: state.document.id,
    loading: state.document.loading
  };
};

const mapDispatchToProps = (dispatch) => ({
  clearTemplate: () => dispatch(setTemplate({})),
  changeTemplate: (template) => dispatch(setTemplate(template)),
  getDoc: (id) => dispatch(getDoc(id)),
  clearId: (id) => dispatch(setId("")),
  clearDoc: () => dispatch(setDoc()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewDocumentEditor);
