import React from "react";
import "./index.scss";

const PricingOrder = (props) => {
  return (
    <div className="pricing__order">
      <div className="pricing__order__inner">
        <div className="title">Choose Your Plan & Order Now!</div>
        <div className="description">
          Writing excellent copy has never been so easy! Nothing is more
          convenient than ResearchAI. With this easy-to-use solution, you can
          get a great copy with the click of a button. That means no more
          putting yourself through torturous writing sessions where you struggle
          to find the right word, sentence, or paragraph.
          <br />
          <br />
          Plus, ResearchAI is loaded with additional tools, including a Blog
          Wizard Tool (Premium Only) and Editing Tools that turn old, poorly
          written content into well-written fresh content.
        </div>
        <div className="pricing__order__inner__block">
          🎉 ResearchAI Does It All for You … Just Choose the <br /> Plan Above
          That is Right for You!
        </div>
      </div>
    </div>
  );
};

PricingOrder.propTypes = {};

export default PricingOrder;
