import React, { useEffect } from "react";
import { Provider } from "react-redux";
import store from "./store";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PrivateRoute from "./components/routing/PrivateRoute";
import Landing from "./components/pages/Landing";
import setAuthToken from "./utils/setAuthToken";
import { loadUser } from "./actions/auth";
import Dashboard from "./components/private/dashboard/index";
import Profile from "./components/private/profile/index";
import CreateDocument from "./components/private/sections/NewDocument";
import TemplateEditor from "./components/private/sections/editor";
import Tutorials from "./components/private/Tutorials";
import Terms from "./components/pages/Terms";
import Blog from "./components/pages/Blog";
import Privacy from "./components/pages/Privacy";
import Payment from "./components/private/Payment";
import Document from "./components/private/sections/Document";
import Documents from "./components/private/sections/Documents";
import FreeTools from "./components/pages/FreeTools";
import Refer from "./components/pages/Refer";
import ForgotPassword from "./components/sections/auth/ForgotPassword";
import SerpTool from "./components/private/sections/SerpTool";
import VideoToolSet from "./components/private/VideoToolSet";
import Login2 from "./components/sections/auth/Login2";
import MegaEditor from "./components/private/sections/MegaEditor";
import ContentBrief from "./components/private/sections/ContentBrief";
import ReadPost from "./components/pages/ReadPost";
import AddBlog from "./components/pages/AddBlog";
import Templates from "./components/private/sections/templates/index";
import Outputs from "./components/private/sections/Outputs/index";
import BeastMode from "./components/private/sections/beastmode/BeastMode";
import Resume from "./components/private/sections/resume/Resume";
import NewDocumentEditor from "./components/private/sections/NewDocumentEditor/index";
import Invoices from "./components/private/invoices/index";
import PricingPage from "./components/pages/Pricing";
import { hideIframe } from "utils/hideIframe";
if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);
  hideIframe()
  return (
    <Provider store={store}>
      <Router>
        <div className="App">
          <Switch>
            <Route exact path="/" component={Landing} />
            <Route exact path="/register" component={Payment} />
            <Route exact path="/login" component={Login2} />
            <Route
              exact
              path="/user-password-reset"
              component={ForgotPassword}
            />
            <Route exact path="/privacy" component={Privacy} />
            <Route exact path="/terms" component={Terms} />
            <Route exact path="/blog" component={Blog} />
            <PrivateRoute exact path="/admin/addblog" component={AddBlog} />

            <Route exact path="/posts/:id" component={ReadPost} />
            <Route exact path="/free-tools" component={FreeTools} />
            <Route exact path="/pricing" component={PricingPage} />
            <Route exact path="/refer-friend" component={Refer} />
            <PrivateRoute exact path="/dashboard" component={Dashboard} />
            <PrivateRoute exact path="/editor" component={MegaEditor} />
            <PrivateRoute
              exact
              path="/content-brief"
              component={ContentBrief}
            />
            <PrivateRoute exact path="/beastmode" component={BeastMode} />
            {/* <PrivateRoute exact path="/newdocument" component={NewDocument} /> */}

            <PrivateRoute exact path="/settings" component={Profile} />
            <PrivateRoute
              exact
              path="/settings/invoices"
              component={Invoices}
            />

            <PrivateRoute exact path="/tutorials" component={Tutorials} />
            <PrivateRoute exact path="/documents" component={Documents} />
            <PrivateRoute exact path="/serp-tool" component={SerpTool} />
            <PrivateRoute
              exact
              path="/tools/script-writer"
              component={VideoToolSet}
            />

            <PrivateRoute exact path="/tools" component={Templates} />
            <PrivateRoute
              exact
              path="/tools/template"
              component={TemplateEditor}
            />
            <PrivateRoute exact path="/ai-outputs" component={Outputs} />
            <PrivateRoute
              exact
              path="/new-document"
              component={CreateDocument}
            />
            <PrivateRoute
              exact
              path="/new-document/editor"
              component={NewDocumentEditor}
            />
            <PrivateRoute exact path="/resume" component={Resume} />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/documents/:id" component={Document} />
          </Switch>
        </div>
      </Router>
    </Provider>
  );
};

export default App;
