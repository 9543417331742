/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { changeMember, logout } from "../../../actions/auth";
import SideBar from "../sections/SideBar/SideBar.jsx";
import "./index.scss";
import Loader from "../../common/Loader";
import PersonalSettings from "./profilePersonal/index.jsx";
import { changeUser } from "../../../actions/auth.js";
import _ from "lodash";
import ProfileBiling from "./profileBiling/index.jsx";
import { validURL } from "../../../validation/url.js";
import { validateEmail } from "../../../validation/email.js";
import ProfileTeam from "./profileTeam";
import Modal from "components/common/Modal";
import video from 'assets/videos/Settings Tutorial.mp4'
import Icon from "components/common/Icon";
// const promise = loadStripe(
//   "pk_test_51LDQmvHUtEEoDk2UiVKDOzC24Oc8cKkG6QrKfXbXByJLov6GoU7wZIE49vXYAKWXFhtvSokATSuCZWNazmnQ1lYk002yScD3l2"
// );

toast.configure();

const Profile = ({
  auth: { user, invitedUser, canceled },
  auth,
  logout,
  updateUser,
  updateTeamMember,
}) => {
  const [isOpenExpireModal,setIsOpenExpireModal] = useState(false)
  const [activeTab, setActiveTab] = useState("Personal");
  const [isTrial, setIsTrial] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isOpenFirstVideo,setIsOpenFirstVideo] = useState(false)
  useEffect(() => {
    if(auth.isAuthenticated && user && user.expired){
      setIsOpenExpireModal(true)
    }
    if (auth.isAuthenticated && user && user.subId === "endtrial") {
      setActiveTab("Biling");
      setIsTrial(true);
    } else if (auth.isAuthenticated && user && !user.subId) {
      setActiveTab("Biling");
      setIsTrial(true);
    } else if (auth.isAuthenticated && user && user.subId === "trial") {
      setActiveTab("Biling");
      setIsTrial(true);
    } else if (auth.isAuthenticated && user && canceled) {
      setActiveTab("Biling");
      setIsTrial(true);
    } 
     if(auth.isAuthenticated && user && user.subId && window.location.hash === '#billing'){
      setActiveTab('Biling')
    }
    setLoading(false)
 
      // else if (auth.isAuthenticated && user && user.expiredWordsLimit) {
      //   setActiveTab("Biling");
      //   setIsTrial(true);
      // }
  }, [user]);
  useEffect(()=>{
    const is = localStorage.getItem('is_watched_video')
    if(!is){
      setIsOpenFirstVideo(true)
      localStorage.setItem('is_watched_video',true)
    }
  },[])
  const handleSave = async (userData) => {
    if (userData.isReset) {
      if (!userData.password && !userData.passwordConfirmation) {
        toast.error("Please fill required fields");
        return;
      }
      if (userData.password && userData.password.length < 8) {
        toast.error("Password does not match");
        return;
      }
      if (userData.password !== userData.passwordConfirmation) {
        toast.error("Password confirmation does not match");
        return;
      }
      const body = {
        email: user.email,
        password: userData.password,
      };
      setLoading(true);
      try {
        await axios.patch("/api/users/reset", body, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        toast.success("Changes saved successfully");
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
      return;
    }
    if (!_.isEqual(userData, user)) {
      setLoading(true);
      try {
        if (!userData.name.length) {
          setLoading(false);
          toast.error("Please fill required fields");
          return;
        }
        if (userData.domain && !validURL(userData.domain)) {
          setLoading(false);
          toast.error("Please fill valid website domain");
          return;
        }
        if (userData.billing && !validateEmail(userData.billing)) {
          setLoading(false);
          toast.error("Please fill valid billing email");
          return;
        }
        if (invitedUser) {
          const { data } = await axios.post("/api/users/change", {
            name: userData.name,
            email: userData.email,
            createdAt: invitedUser.createdAt,
            invitedBy: invitedUser.invitedBy,
            invitedCount: invitedUser.invitedCount,
            role: invitedUser.role,
            __v: 0,
            language: userData.language,
            id: invitedUser._id,
          });
          toast.success("Changes saved successfully");
          updateTeamMember(data.user, data.token);
        } else {
          const { data } = await axios.post("/api/users/change", {
            ...userData,
          });
          updateUser(data.user, data.token);
          toast.success("Changes saved successfully");
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error(error);
      }
    }
  };
  return (
    <div className="profile">
      {loading && <Loader />}
      {isOpenExpireModal && (
       <Modal
          hasCloseButton={false}
          onClose={()=>setIsOpenExpireModal(false)}
        >
          <div className="account__suspended__modal">
              <div className="title">
                Account Suspended
              </div>
              <div className="subtitle">
                Your account has been suspended because your payment method could not be charged. Please update your payment method now.
              </div>
              <button className='btn-main' onClick={()=>setIsOpenExpireModal(false)}>
                Update
              </button>
          </div>
        </Modal>
      )}
      <SideBar user={user && user.name} />
      <div className={!isTrial ? "profile__container" : ""}>
        <div className="wrapper">
          <div className="profile__top">
            <div className="wrapper__title">Profile Settings</div>
           <div className="profile__top__flex">
           <button onClick={() => setIsOpenFirstVideo(true)} className="btn-second">
              Watch Video
            </button>
           <button onClick={() => logout()} className="btn-error">
              Logout
            </button>
           </div>
          </div>
          <div className="profile__tabs">
            {!isTrial && (
              <div
                role="presentation"
                onClick={() => {
                  setActiveTab("Personal");
                }}
                className={`profile__tab ${
                  activeTab === "Personal" && "profile__tab__active"
                }`}
              >
                Personal
              </div>
            )}
            {!isTrial && (
              <div
                role="presentation"
                onClick={
                  invitedUser && invitedUser.role === 0
                    ? () => {}
                    : () => {
                        setActiveTab("Team Members");
                      }
                }
                className={`profile__tab ${
                  activeTab === "Team Members" && "profile__tab__active"
                }`}
              >
                Team Members
              </div>
            )}
            <div
              role="presentation"
              onClick={
                invitedUser
                  ? () => {}
                  : () => {
                      setActiveTab("Biling");
                    }
              }
              className={`profile__tab ${
                activeTab === "Biling" && "profile__tab__active"
              }`}
            >
              Billing
            </div>
          </div>
          <div className="profile__content">
            {activeTab === "Personal" && !isTrial && (
              <PersonalSettings
                invitedUser={invitedUser}
                user={user}
                handleSave={handleSave}
              />
            )}
            {activeTab === "Team Members" &&
              !isTrial &&
              (invitedUser ? invitedUser.role !== 0 : true) && (
                <ProfileTeam user={user} handleSave={handleSave} />
              )}
            {activeTab === "Biling" && (
              <ProfileBiling isCanceled={auth.canceled} user={user} handleSave={handleSave} />
            )}
          </div>
        </div>
      </div>
      {isOpenFirstVideo && (
          <div className="videoPlayer">
            <div
              className="closer"
              role="presentation"
              onClick={() => {
                setIsOpenFirstVideo(false)
              }}
            ></div>
            {/* <iframe
              width="90%"
              height="90%"
              src="https://www.youtube.com/embed/LjeHXjrJ8XE"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen={true}
            ></iframe> */}
              <div className="videoPlayer__video">
              <video src={video} controls autoPlay></video>
              <Icon name="Close" onClick={() => setIsOpenFirstVideo(false)} />
            </div>
          </div>
        )}
    </div>
  );
};

Profile.propTypes = {
  updateUser: PropTypes.func,
  updateTeamMember: PropTypes.func,
  auth: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
};
const mapStatetoProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  updateUser: (user, token) => dispatch(changeUser(user, token)),
  updateTeamMember: (user, token) => dispatch(changeMember(user, token)),
  logout: () => dispatch(logout()),
});

export default connect(mapStatetoProps, mapDispatchToProps)(Profile);
