import React from "react";
import PropTypes from "prop-types";
import "./index.scss";
import Switcher from "components/common/Switcher";

const PricingSwitcher = ({ isSwitched, setIsSwitched }) => {
  return (
    <div className="pricing__switcher">
      <div className="pricing__switcher__text" style={{ marginRight: "12px" }}>
        Monthly
      </div>
      <Switcher checked={isSwitched} setChecked={setIsSwitched} />
      <div className="pricing__switcher__text" style={{ marginLeft: "12px" }}>
        Yearly
      </div>
      <div className="pricing__switcher__prsent" style={{ marginLeft: "8px" }}>
        20% discount
      </div>
    </div>
  );
};

PricingSwitcher.propTypes = {
  isSwitched: PropTypes.bool,
  setIsSwitched: PropTypes.func,
};

export default PricingSwitcher;
