import React from "react";
import "./index.scss";
import PropTypes from "prop-types";

const Switcher = ({ checked, setChecked }) => {
  return (
    <div className="switch">
      <label className="toggle">
        <input
          className={`toggle-checkbox `}
          type="checkbox"
          checked={checked}
          onChange={(e) => setChecked(e.target.checked)}
        />
        <div className={`toggle-switch `} />
      </label>
    </div>
  );
};

Switcher.propTypes = {
  checked: PropTypes.bool,
  setChecked: PropTypes.func,
};

export default Switcher;
