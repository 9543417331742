import React from "react";
import image from "assets/images/Rocket.png";
import "./index.scss";

const PricingSecondSection = (props) => {
  return (
    <div className="pricing__second__section">
      <div className="pricing__second__section__title">
        Money-Back Guarantee
      </div>
      <div className="pricing__second__section__content">
        <div className="pricing__second__section__left">
          <div className="pricing__second__section__left__text">
            Both plans are backed by a 100% money-back guarantee. Place your
            order now and if you are not satisfied with ResearchAI for any
            reason at all, just contact us within seven days of buying the
            product for a full refund of your purchase price.
          </div>
          <br />
          <div className="pricing__second__section__left__text">
            We created this product to help you get the content you need to grow
            your brand and expand your business. We don't want your money if it
            doesn’t help you do that.
            <br />
            <br />
            So go ahead and order ResearchAI; if it is not what you expected,
            simply notify us within seven days for a refund.
          </div>
          <div className="pricing__second__section__left__bottom">
            You have nothing to lose by taking advantage of <br /> this offer …
          </div>
          <div className="pricing__second__section__left__block">
            👏 and access to an incredible treasure trove of <br /> fresh,
            original content to gain!
          </div>
        </div>
        <div className="pricing__second__section__right">
          <img src={image} alt="" />
        </div>
      </div>
    </div>
  );
};

PricingSecondSection.propTypes = {};

export default PricingSecondSection;
