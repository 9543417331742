import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
// import { setAlert } from "../../actions/alert";
import PropTypes from "prop-types";
import { register } from "../../../actions/auth";
import { toast } from "react-toastify";
import Loading from "../../common/Loading";
import { useHistory } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Header from "../../layout/Header";

toast.configure();
const Register = ({ register, isAuthenticated,loading }) => {
  const urlParams = new URLSearchParams(window.location.search);
  let history = useHistory();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    plan: "price_1JiYXQJotKJS6JVSttXRhvj3",
    hear: "",
    referral: "",
    subId: "trial",
    isInvited: urlParams.get('isInvited')
  });

  useEffect(()=>{
    setFormData({
      ...formData,
      ...history.location.state
    })
  },[])

  const { name, email, password, hear, referral, subId, isInvited } = formData;
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value.trim() });

  const onSubmit = async (e) => {
    e.preventDefault();
    register({
      name,
      email,
      password,
      hear,
      referral,
      subId,
      isInvited,
    });
  };
  // const handleSubmitSub = async (event) => {
  //   if (!stripe || !elements) {
  //     // Stripe.js has not yet loaded.
  //     // Make sure to disable form submission until Stripe.js has loaded.
  //     return;
  //   }

  //   const result = await stripe.createPaymentMethod({
  //     type: 'card',
  //     card: elements.getElement(CardElement),
  //     billing_details: {
  //       email: email,
  //     },
  //   });

  //   if (result.error) {
  //     toast.error("There was an issue with your payment! Try again.", {
  //       position: toast.POSITION.TOP_RIGHT
  //     });
  //     setLoading(false)
  //     // console.log(result.error)
  //   } else {
  //     const res = await axios.post('/api/payments/subscribe', {'payment_method': result.paymentMethod.id, 'email': email, 'plan': plan});
  //     // eslint-disable-next-line camelcase
  //     // const {client_secret, status} = res.data;
  //     const {customerId, planId, subId} = res.data

  //     if (customerId.length === 0) {
  //       // stripe.confirmCardPayment(client_secret).then(function(result) {
  //       //   if (result.error) {

  //           toast.error("There was an issue with your payment card! Try again.", {
  //             position: toast.POSITION.TOP_RIGHT
  //           });
  //           setLoading(false)
  //           // console.log(result.error);
  //           // Display error message in your UI.
  //           // The card was declined (i.e. insufficient funds, card has expired, etc)
  //       //   } else {
  //       //     register({ name, email, password, hear, referral, customerId, planId });
  //       //     toast.success("Welcome to Research AI!", {
  //       //       position: toast.POSITION.TOP_CENTER
  //       //     });

  //       //     // Show a success message to your customer
  //       //   }
  //       // });
  //     } else {
  //       register({ name, email, password, hear, referral, customerId, planId, subId });
  //       toast.success("Welcome to Research AI!", {
  //         position: toast.POSITION.TOP_CENTER
  //       });
  //       // No additional information was needed
  //       // Show a success message to your customer
  //     }
  //   }
  // };

  if (isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }
  return (
    <div>
      <Header />
      <section className="bg-black overflow-hidden">
        <div className="py-15 py-xl-20 d-flex flex-column container-fluid level-3 min-vh-100">
          <div className="row align-items-center justify-content-center my-auto">
            <div className="col-md-10 col-lg-8 col-xl-5">
              <div className="card">
                <div className="card-header bg-white text-center pb-0">
                  <h4 className="fs-4 mb-1">
                    Write faster like never before <br /> with Research AI.
                  </h4>
                  <h6 className="text-secondary">
                    Start your FREE Trial by filling up the form.
                  </h6>
                </div>
                <div className="card-body bg-white">
                  <form onSubmit={(e) => onSubmit(e)}>
                    <div className="form-floating mb-2">
                      <input
                        className="form-control"
                        type="email"
                        placeholder="jeff@amazon.com"
                        name="email"
                        value={email}
                        onChange={(e) => onChange(e)}
                        id="email"
                        required
                      />

                      <label htmlFor="floatingInput">Email address</label>
                    </div>
                    <div className="form-floating mb-2">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Jeff Bezos"
                        name="name"
                        value={name}
                        onChange={(e) => onChange(e)}
                        id="name"
                        required
                      />
                      <label htmlFor="floatingPassword">Your name</label>
                    </div>
                    <div className="form-floating mb-2">
                      <input
                        type="password"
                        placeholder="Your password"
                        name="password"
                        value={password}
                        onChange={(e) => onChange(e)}
                        id="password"
                        className="form-control"
                        required
                      />
                      <label htmlFor="floatingPassword">Your Password</label>
                    </div>
                    {
                      // <div className="form-floating mb-2">
                      //   <h6>Select a plan</h6>
                      //   <select
                      //     name="plan"
                      //     id="plan"
                      //     value={plan}
                      //     onChange={(e) => onChange(e)}
                      //     className="form-select form-select-lg mb-3"
                      //     required
                      //   >
                      //     <option value="price_1JiYXQJotKJS6JVSttXRhvj3">
                      //       Basic plan $19.99/m
                      //     </option>
                      //     <option value="price_1JiYg7JotKJS6JVSGvn8ALN3">
                      //       Standard plan $25.99/m
                      //     </option>
                      //     <option value="price_1KC82UJotKJS6JVSnAg4bOkb">
                      //       Pro plan $59/m
                      //     </option>
                      //   </select>
                      // </div>
                    }
                    <div className="form-floating mb-2">
                      <h6>How did you hear about us?</h6>
                      <select
                        name="hear"
                        value={hear}
                        onChange={(e) => onChange(e)}
                        id="hear"
                        className="form-select form-select-lg mb-3"
                      >
                        <option selected>Choose an option</option>
                        <option value="facebook">Facebook</option>
                        <option value="tiktok">Tik Tok</option>
                        <option value="google">Google search</option>
                        <option value="friend">Friends</option>
                        <option value="school">At School</option>
                        <option value="reddit">Reddit</option>
                        <option value="producthunt">ProductHunt</option>
                      </select>
                    </div>
                    {/* <div className="form-floating mb-2">
                      <h6>Referral code (Optional)</h6>
                      <input
                        name="referral"
                        id="referral"
                        value={referral}
                        onChange={(e) => onChange(e)}
                        className="form-control"
                      />
                    </div> */}
                    <div className="form-floating mb-2">
                      {
                        // <h6 className="mt-5 font-weight-bold">
                        //   Free for 7 days. Cancel any time.
                        // </h6>
                        // <h6 className="text-primary small">
                        //   Payment Card details. You won't be charged during trial.
                        // </h6>
                        // <CardElement className="form-control" />
                      }
                      <br />

                      {loading ? (
                        <Loading />
                      ) : (
                        <div className="d-flex flex-column align-items-end">
                          <button className="btn btn-lg btn-primary form-control">
                            Create my account
                          </button>
                        </div>
                      )}
                    </div>
                  </form>
                </div>
              </div>
                <div className="card-footer bg-opaque-black inverted text-center">
                  <p className="text-secondary">
                    Already registered?{" "}
                    <Link to="/login" className="underline">
                      Login
                    </Link>
                  </p>
                </div>
            </div>
          </div>
        </div>
        <figure
          className="background background-overlay"
          style={{ backgroundImage: "url('./assets/images/small-5.jpg')" }}
        ></figure>
      </section>
    </div>
  );
};

Register.propTypes = {
  // setAlert: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
});

export default connect(mapStateToProps, { register })(Register);
