import React, { useState } from "react";
import "./index.scss";
import { uniqueId } from "lodash";
import Icon from "components/common/Icon";
import PlanSize from "components/common/PlanSize";
import { Link } from "react-router-dom";

const pm = [
  "To 330k+ words",
  "Unlimited users + all features",
  "Priorety support",
  "+35 Templates",
  "+25 Languages",
];

const price = {
  "50k": "59",
};

const PricingPremiumPlan = ({ oldPrice, price, subText, type }) => {
  const [variant, setVariant] = useState("50k");
  const getPrice = () => {
    switch (variant) {
      case "50k":
        return type === "yearly" ? "$55.00" : "$69.00";
      case "100k":
        return type === "yearly" ? "$79.00" : "$99.00";
      default:
        return type === "yearly" ? "$199.00" : "$249.00";
    }
  };
  const getOldPirce = () => {
    if (type === "yearly") {
      switch (variant) {
        case "50k":
          return "$69.00";
        case "100k":
          return "$99.00";
        default:
          return "$249.00";
      }
    }
  };
  return (
    <div className="pricing__premium">
      <div className="pricing__premium__top">
        <div className="pricing__premium__type">Premium Plan</div>
        <div className="pricing__premium__typeText">
          Good for Unlimited Users
        </div>
      </div>
      <div className="pricing__premium__divider"></div>
      <div className="pricing__premium__middle">
        <div className="pricing__premium__old">{getOldPirce()}</div>
        <div className="pricing__premium__price">{getPrice()}</div>
        <div className="pricing__premium__price__text">{subText}</div>
        <PlanSize
          selectedCount={variant}
          onSelect={(value) => setVariant(value)}
        />
      </div>
      <div className="pricing__premium__divider"></div>
      <div className="pricing__premium__functions">
        {pm.map((item) => {
          return (
            <div className="pricing__premium__function" key={uniqueId()}>
              <Icon name="Function" />
              {item}
            </div>
          );
        })}
      </div>
      <div className="pricing__premium__start">
        <Icon name="Start" />
      </div>
      <Link
        className="btn-third"
        to="/register"
        style={{ width: "100%", marginTop: "auto" }}
      >
        Start Writing
      </Link>
    </div>
  );
};

PricingPremiumPlan.propTypes = {};

export default PricingPremiumPlan;
