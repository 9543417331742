import React from "react";
import FacItem from "components/common/FAQitem";
import "./index.scss";

const PricingFAQ = (props) => {
  return (
    <div className="pricing__faq" id='faq'>
      <div className="pricing__faq__container">
        <div className="pricing__faq__container__title">
          Frequently Asked Questions
        </div>
        <div className="subTitle">You have questions, we have answers</div>
        <div className="content">
          <div className="content__block__first">💡</div>
          <FacItem
            title="What can I create with ResearchAI?"
            optionText="The better question is, what can’t you create with ResearchAI? This powerful artificial intelligence-driven writing tool allows you to create blog posts, marketing copy, product descriptions, social media posts, and much more. Plus, we are constantly introducing new features that allow it to do even more. "
          />
          <FacItem
            title="Is there a money-back guarantee?"
            optionText="Both plans are backed by a 100% money-back guarantee. Place your order now and if you are not satisfied with ResearchAI for any reason at all, just contact us within seven days of buying the product for a full refund of your purchase price."
          />
          <FacItem
            title="How many languages does ResearchAI Support?"
            isLanguageSection={true}
            optionText="This powerful AI tool currently supports 25 languages, and more are constantly added. Currently supported languages include:"
          />
          <FacItem
            title="Can I get a demo of ResearchAI?"
            optionText="Of course. Take ResearchAI for a spin on our free plan. Sign up for our newsletter and enjoy 5,000 words for free!"
          />
          <FacItem
            title="Who owns the content generated by ResearchAI?"
            optionText="All generated content is yours and can be used for your business without any issues."
          />
          <FacItem
            title="Are there any contracts to sign?"
            optionText="Nope. No long term contracts need to be signed. We have flexible monthly plans. No long term contracts, no long term commitments and cancel any time."
          />
          <div className="content__block__second">👀</div>
        </div>
      </div>
    </div>
  );
};

PricingFAQ.propTypes = {};

export default PricingFAQ;
