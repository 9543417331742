import React from "react";
import { Link } from "react-router-dom";
import "./index.scss";
import Icon from "../../common/Icon";
import ConnectForm from "components/common/Connect";

const Footer = () => {
  return (
    <div className="footer__new">
      <div className="footer__new__wrapper">
        <div className="footer__new__top">
          <div className="footer__new__top__logo">
            <Icon name="LogoLanding" />
          </div>
          <div className="footer__new__top__navigation">
            <div className="footer__new__top__navigation__left">
              {/* <Link to="/terms">Terms & conditions</Link> */}
              <a href="#">Home</a>
              <Link to="/pricing" onClick={()=>window.scrollTo({top:0})}>Pricing</Link>
              <Link to="/blog">Our Blog</Link>
              <a href="/#faq">FAQ</a>
              <a
                href="https://www.facebook.com/groups/474436847390154"
                target="_blank"
                rel="noreferrer"
              >
                Join Our Facebook Group
              </a>
            </div>
            {/* <div className="footer__new__top__navigation__right">
              <a href="#about">About Us</a>
            </div> */}
          </div>
          <ConnectForm />
          {/* <div className="footer__new__top__connect">
            <div className="connect__text">Let’s connect</div>
            <div className="connect__input">
              <input type="text" placeholder="Enter your email to get a 7-day free trial with 5,000 words written by ResearchAI”" />
              <a href="/" className="btn-main">
                <Icon name="ArrowEnter" />
              </a>
            </div>
          </div> */}
        </div>
        <div className="footer__new__bottom">
          <div className="footer__new__bottom__left">
            <Link
              to='/terms'
              >
                Terms & conditions
              </Link>
              <Link
                to='/privacy'
                >
                  Privacy policy
                </Link>
          </div>
          <div className="footer__new__bottom__right">
          Copyrights © 2022 ResearchAI
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
