import React, {useState} from 'react'
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
// import { setAlert } from "../../actions/alert";
import PropTypes from "prop-types";
import { login } from '../../../actions/auth';
import Loader from '../../common/Loader';

const Login = ({login, isAuthenticated,loading}) => {
  const [formData, setFormData] = useState({
    email:'',
    password: ''

  })
  const { email, password} = formData;
  const onChange = (e) =>
  setFormData({ ...formData, [e.target.name]: e.target.value });
  
  const onSubmit = async (e) => {
    e.preventDefault();
    login( email, password );

  };
  if (isAuthenticated) {
    return <Redirect to={{ pathname: '/dashboard' }} />
  }

  return (
    <div>
      {loading ? (<Loader />) : (

        <section className="bg-black overflow-hidden">
    <div className="py-15 py-xl-20 d-flex flex-column container level-3 min-vh-100">
      <div className="row align-items-center justify-content-center my-auto">
        <div className="col-md-10 col-lg-8 col-xl-5">

          <div className="card">
            <div className="card-header bg-white text-center pb-0">
              <h5 className="fs-4 mb-1">Welcome back.</h5>
            </div>
            <div className="card-body bg-white">
           <p className="mb-2"> Enter your credentials to sign in.</p>
              <form onSubmit={(e) => onSubmit(e)}>
                <div className="form-floating mb-2">
                  <input 
                    type="email" 
                    className="form-control" 
                    id="floatingInput" 
                    placeholder="jeff@amazon.com" 
                    
                    name="email"
                    value={email}
                    onChange={(e) => onChange(e)} 
                    
                    required
                    />
                  <label htmlFor="floatingInput">Email address</label>
                </div>
                <div className="form-floating mb-2">
                  <input 
                    type="password" 
                    className="form-control" 
                    id="floatingPassword" 
                    placeholder="Password" 
                    name="password"
                    value={password}
                    onChange={(e) => onChange(e)} 
                    
                    required
                    />
                  <label htmlFor="floatingPassword">Password</label>
                </div>
                <div className="d-grid mb-2">
                  <button className="btn btn-lg btn-primary">Sign In</button>
                </div>
  
              </form>
            </div>
          </div>
            <div className="card-footer  inverted text-center">
              <p className="text-secondary">Don't have an account yet? <Link to="/register"
                  className="underline">Register</Link>
              </p>
            </div>
        </div>
      </div>
    </div>
    <figure className="background background-overlay" style={{backgroundImage: "url('/dist/assets/images/newyork.jpg')"}}>
    </figure>
  </section>
    )}
    </div>
  )
}

Login.propTypes = {
  // setAlert: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.isLoading,
});

export default connect(mapStateToProps, { login })(Login);




