import React, { useState } from "react";
import { useHistory } from "react-router";
import { Link, Redirect } from "react-router-dom";
import { logout } from "../../../../actions/auth";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import IconButton from '@mui/material/IconButton';
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import "./index.scss";
import Icon from "../../../common/Icon";
import userImage from "../../../../assets/images/user.jpg";
import Tooltip from '@mui/material/Tooltip';
import Modal from "../../../common/Modal";
import styled from 'styled-components'
toast.configure();

const ToBeStyledTooltip = ({ className, ...props }) => (
  <Tooltip {...props} classes={{ tooltip: className }} />
);
export const StyledTooltip = styled(ToBeStyledTooltip)(({ theme }) => ({
  backgroundColor: '#2F2F2F',
  color: '#FFFFFF',
  borderRadius: '8px',
  fontSize: '12px',
  fontWeight: '500',
  lineHeight: '18px',
  border: '1px solid #dadde9',
  padding: '8px 12px',
  zIndex:'9999',
}));

const SideBar = ({ auth: { isAuthenticated, loading, user, canceled }, logout }) => {

  let history = useHistory();
  const [isOpenReferModal, setIsOpenReferModal] = useState(false);
  const activeTab = history.location.pathname.split("/")[1];
  // if(isAuthenticated && user && user.expiredWordsLimit){
  //   return <Redirect to={{ pathname: "/settings" }} />;
  // }
  if (!isAuthenticated) {
    // return <Redirect to={{ pathname: "/login" }} />;
  } else if (isAuthenticated === true && user && user.subId === "endtrial") {
    return <Redirect to={{ pathname: "/settings" }} />;
  } else if (isAuthenticated === true && user && !user.subId) {
    return <Redirect to={{ pathname: "/settings" }} />;
  } else if (isAuthenticated === true && user && user.subId === "trial") {
    return <Redirect to={{ pathname: "/settings" }} />;
  }else if (isAuthenticated === true && user && canceled && !window.location.href.includes("invoices")) {
    return <Redirect to={{ pathname: "/settings" }} />;
  }else if(isAuthenticated === true && user && canceled && window.location.href.includes("invoices")){
    return <Redirect to={{ pathname: "/settings/invoices" }} />;
  }
  return (
    <>
      {isOpenReferModal && (
        <Modal onClose={() => setIsOpenReferModal(false)}>
          <div className="sidebar__modal">
            <div className="sidebar__modal__title">
              Get 1 month Free for each friend you refer.
            </div>
            <div className="sidebar__modal__subtitle">
              Get one month free by referring friends and collegues. Simply ask
              your referrals enter your name on referral box when signing up and you get 1 month free
            </div>
          </div>
        </Modal>
      )}
      <div className="sidebar">
        <div className="sidebar__wrapper">
          <div className="sidebar__logo">
            <Link to="/dashboard">
              <Icon name="Logo" />
            </Link>
          </div>
          <ul className="sidebar__list">
            <li className="sidebar__list__item">
              <Link
                className={`sidebar__list__item__link ${
                  activeTab === "dashboard" &&
                  "sidebar__list__item__link__active"
                } `}
                to="/dashboard"
              >
                <Icon name="Dashboard" />
                Dashboard
              </Link>
            </li>
            <li className="sidebar__list__item">
              <Link
                className={`sidebar__list__item__link ${
                  activeTab === "tools" && "sidebar__list__item__link__active"
                } `}
                to="/tools"
              >
                <Icon name="Ai" />
                AI Templates
              </Link>
            </li>
            <li className="sidebar__list__item">
              <Link className="sidebar__list__item__link" to="/new-document">
                <Icon name="NewDocument" />
                New Document
              </Link>
              <Link to="/new-document">
                <div
                  className="sidebar__plus"
                > <StyledTooltip
                    title="Create a new document"
                    arrow
                    placement="right"
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: 'common.black',
                          '& .MuiTooltip-arrow': {
                            color: 'common.black',
                          },
                        },
                      },
                    }}
                  >
                    <IconButton>
                      <Icon name="Plus"/>
                    </IconButton>
                  </StyledTooltip>
                </div>
              </Link>
            </li>
            <li className="sidebar__list__item">
              <Link
                className={`sidebar__list__item__link ${
                  activeTab === "documents" &&
                  "sidebar__list__item__link__active"
                } `}
                to="/documents"
              >
                <Icon name="MyDocuments" />
                My Documents
              </Link>
            </li>
            <li className="sidebar__list__item">
              <Link
                className={`sidebar__list__item__link ${
                  activeTab === "serp-too" &&
                  "sidebar__list__item__link__active"
                }`}
                to="/serp-tool"
              >
                <Icon name="KResearch" />
                Keyword Research
              </Link>
              <div className="sidebar__list__item__new">New</div>
            </li>
            <li className="sidebar__list__item">
              <Link
                className={`sidebar__list__item__link ${
                  activeTab === "content-brief" &&
                  "sidebar__list__item__link__active"
                }`}
                to="/content-brief"
              >
                <Icon name="Brief" />
                Content Brief
              </Link>
              <div className="sidebar__list__item__new">New</div>
            </li>
            <li className="sidebar__list__item">
              <Link
                className={`sidebar__list__item__link ${
                  activeTab === "ai-outputs" &&
                  "sidebar__list__item__link__active"
                }`}
                to="/ai-outputs"
              >
                <Icon name="AiOutputs" />
                Saved AI Outputs
              </Link>
            </li>
          </ul>
          {/* <div className="sidebar__referral">
            <div className="sidebar__referral__elipse sidebar__referral__elipse__1"></div>
            <div className="sidebar__referral__content">
              <div className="title">Get 1 month Free</div>
              <div className="text">
                Refer a friend and get <br /> a free month
              </div>
              <button
                onClick={() => setIsOpenReferModal(true)}
                className="btn-second btn-second-b"
              >
                Refer a friend
              </button>
            </div>
            <div className="sidebar__referral__elipse sidebar__referral__elipse__2"></div>
          </div> */}
          <div className="sidebar__user">
            <div className="sidebar__user__info">
              <div className="sidebar__user__image">
                <img src={userImage} alt="" />
              </div>
              <div className="sidebar__user__title">
                {user ? user.name : ""}
              </div>
            </div>
            <Link
              className={`sidebar__user__button ${
                activeTab === "settings" && "sidebar__list__item__link__active"
              }`}
              to="/settings"
            >
              <Icon name="Settings" />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

SideBar.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(SideBar);

// <div>
//       <div className="row justify-content-beginnig sticky-top">
//         <div className="col-lg-12 split-sidebar">
//           <div className="navbar-expand-lg navbar-expand-lg-collapse-block navbar-light">
//             <button
//               type="button"
//               className="navbar-toggler btn  py-3"
//               aria-label="Toggle navigation"
//               aria-expanded="false"
//               aria-controls="sidebarNav"
//               data-bs-toggle="collapse"
//               data-bs-target="#sidebarNav"
//             >
//               <span className="d-flex justify-content-between align-items-center ">
//                 <span className="h5 text-black mb-0">Menu</span>
//                 <i className="bi bi-list h4 margin-left-3"></i>
//               </span>
//             </button>

//             <div
//               id="sidebarNav"
//               className="collapse navbar-collapse"
//               style={{ zIndex: 9999 }}
//             >
//               <div className="p-2 p-lg-0">
//                 <div id="documents-area" className="mt-3 mt-lg-0">
//                   <Link
//                     className="nav-item text-black d-flex justify-content-between align-items-center px-0 p-1"
//                     to="/dashboard"
//                   >
//                     <h6 className="text-dark">
//                       {" "}
//                       <i className="bi bi-house-fill"></i> Dashboard
//                     </h6>
//                   </Link>
//                   <hr />
//                   <span
//                     style={{ cursor: "pointer" }}
//                     onClick={() => history.push("/newdocument")}
//                     className="nav-item text-black d-flex justify-content-between align-items-center px-0 p-1"
//                   >
//                     <p>
//                       <i className="bi bi-file-earmark-plus"></i> New document
//                     </p>
//                   </span>
//                   <Link
//                     className="nav-item text-black d-flex justify-content-between align-items-center px-0 p-1"
//                     to="/documents"
//                   >
//                     <p className="text-dark">
//                       {" "}
//                       <i className="bi bi-files"></i> My documents
//                     </p>
//                   </Link>

//                   <Link
//                     className="nav-item text-black d-flex justify-content-between align-items-center px-0 p-1"
//                     to="/settings"
//                   >
//                     <p className="text-dark">
//                       <i className="bi bi-gear"></i> Profile settings
//                     </p>
//                   </Link>

//                   <Link
//                     className="nav-item text-black d-flex justify-content-between align-items-center px-0 p-1"
//                     to="/serp-tool"
//                   >
//                     <p className="text-dark">
//                       <i className="bi bi-google"></i> Keyword Research{" "}
//                       <span className="badge rounded-pill bg-primary">New</span>
//                     </p>
//                   </Link>
//                   <Link
//                     className="nav-item text-black d-flex justify-content-between align-items-center px-0 p-1"
//                     to="/content-brief"
//                   >
//                     <p className="text-dark">
//                       <i className="bi bi-card-text"></i> Content Brief{" "}
//                       <span className="badge rounded-pill bg-primary">New</span>
//                     </p>
//                   </Link>

//                   <Link
//                     className="nav-item text-black d-flex justify-content-between align-items-center px-0 p-1"
//                     to="/tools"
//                   >
//                     <p className="text-black">
//                       <i className="bi bi-columns-gap"></i> AI Templates
//                     </p>
//                   </Link>
//                   <Link
//                     className="nav-item text-black d-flex justify-content-between align-items-center px-0 p-1"
//                     to="/ai-outputs"
//                   >
//                     <p className="text-black">
//                       <i className="bi bi-heart-fill"></i> Saved AI Outputs
//                     </p>
//                   </Link>
//                   <hr />
//                   <a
//                     className="nav-item text-black d-flex justify-content-between align-items-center px-0 p-1"
//                     target="_blank"
//                     href="https://bloossom.typeform.com/to/IKCrahIz"
//                     rel="noreferrer"
//                   >
//                     <p className="text-black">
//                       <i className="bi bi-chat-right-quote"></i> Share Feedback
//                     </p>
//                   </a>
//                   <a
//                     className="nav-item text-black d-flex justify-content-between align-items-center px-0 p-1"
//                     target="_blank"
//                     href="https://www.facebook.com/groups/474436847390154"
//                     rel="noreferrer"
//                   >
//                     <p className="text-black">
//                       <svg
//                         xmlns="http://www.w3.org/2000/svg"
//                         width="16"
//                         height="16"
//                         fill="currentColor"
//                         className="bi bi-meta"
//                         viewBox="0 0 16 16"
//                       >
//                         <path
//                           fillRule="evenodd"
//                           d="M8.217 5.243C9.145 3.988 10.171 3 11.483 3 13.96 3 16 6.153 16.001 9.907c0 2.29-.986 3.725-2.757 3.725-1.543 0-2.395-.866-3.924-3.424l-.667-1.123-.118-.197a54.944 54.944 0 0 0-.53-.877l-1.178 2.08c-1.673 2.925-2.615 3.541-3.923 3.541C1.086 13.632 0 12.217 0 9.973 0 6.388 1.995 3 4.598 3c.319 0 .625.039.924.122.31.086.611.22.913.407.577.359 1.154.915 1.782 1.714Zm1.516 2.224c-.252-.41-.494-.787-.727-1.133L9 6.326c.845-1.305 1.543-1.954 2.372-1.954 1.723 0 3.102 2.537 3.102 5.653 0 1.188-.39 1.877-1.195 1.877-.773 0-1.142-.51-2.61-2.87l-.937-1.565ZM4.846 4.756c.725.1 1.385.634 2.34 2.001A212.13 212.13 0 0 0 5.551 9.3c-1.357 2.126-1.826 2.603-2.581 2.603-.777 0-1.24-.682-1.24-1.9 0-2.602 1.298-5.264 2.846-5.264.091 0 .181.006.27.018Z"
//                         />
//                       </svg>{" "}
//                       Join Facebook Group
//                     </p>
//                   </a>
//                   {user && user.subId && user.subId !== "trial" ? (
//                     ""
//                   ) : (
//                     <Link
//                       className="nav-item btn btn-primary  rounded d-flex justify-content-center align-items-center px-0 p-1"
//                       to="/settings"
//                     >
//                       Upgrade to Premium
//                     </Link>
//                   )}
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
