import React from "react";
import "./index.scss";
import Icon from "components/common/Icon";

const PricingSection = (props) => {
  return (
    <div className="pricing__section">
      <div className="pricing__section__title">
        Simply Choose the Plan That is Right for You!
      </div>
      <div className="pricing__section__subTitle">
        With Our Free Trial Offer, You Have Nothing to Lose
      </div>
      <div className="pricing__section__block">
        🔥 ALL Plans Come With a 7-Day Risk-Free Trial!
      </div>
      <div className="pricing__section__text">
        If ResearchAI Doesn’t Deliver as Promised, Simply Notify Us Within 7
        Days <br />
        to Receive a Refund of Your Purchase Price
      </div>
      <div className="pricing__section__items">
        <div className="pricing__section__item first">
          <div className="pricing__section__item__block">
            <Icon name="PricingFirst" />
          </div>
          <div className="pricing__section__item__title">
            Produce course content
          </div>
        </div>
        <div className="pricing__section__item second">
          <div className="pricing__section__item__block">
            <Icon name="PricingSecond" />
          </div>
          <div className="pricing__section__item__title">
            Generate engaging marketing content{" "}
          </div>
        </div>
        <div className="pricing__section__item third">
          <div className="pricing__section__item__block">
            <Icon name="PricingThird" />
          </div>
          <div className="pricing__section__item__title">
            Polish content using AI-driven editing tools
          </div>
        </div>
        <div className="pricing__section__item fourth">
          <div className="pricing__section__item__block">
            <Icon name="PricingFourt" />
          </div>
          <div className="pricing__section__item__title">
            Free yourself and your staff up to work on other important tasks
          </div>
        </div>
      </div>
    </div>
  );
};

PricingSection.propTypes = {};

export default PricingSection;
