import React from "react";
import Header from "components/layout/Header";
import PricingHero from "./PricingComponents/PricingHero";
import PricingSection from "./PricingComponents/PricingSection";
import Footer from "components/layout/Footer/index";
import PricingSecondSection from "./PricingComponents/PricingSecondSection";
import PricingOrder from "./PricingComponents/PricingOrder";
import PricingFAQ from "./PricingComponents/PricingFAQ";

const PricingPage = () => {
  return (
    <div>
      <Header />
      <PricingHero />
      <div style={{ background: "#FAFBFC" }}>
        <PricingSection />
        <PricingSecondSection />
      </div>
      <PricingOrder />
      <PricingFAQ />
      <Footer />
    </div>
  );
};

PricingPage.propTypes = {};

export default PricingPage;
