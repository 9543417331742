import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./index.scss";
import Image from "assets/images/Hero.png";
import Icon from "components/common/Icon";
import video from "assets/videos/ResearchAI Demo.mp4";

const Hero = () => {
  const [isOpenVide, setIsOpenVide] = useState(false);
  return (
    <div className="hero__m">
      <div className="hero__item">
        <div className="hero__item__content container">
          <div className="hero__item__top">
            <div className="hero__item__title">
              The Ultimate <span>AI Writing Tool</span> For Creators!
            </div>
            <div className="hero__item__subtitle">
              ResearchAI allows creators to write course and marketing content
              10X faster! This is the perfect solution for you if you want to
              create a course but have been hesitating due to the work involved.
            </div>
            <div className="hero__item__buttons">
              <Link to="/register" className="btn-main">
                Start Writing Now
              </Link>
              {/* <button className="btn-third" onClick={() => setIsOpenVide(true)}>
                <Icon name="Play" /> Watch Video
              </button> */}
            </div>
          </div>
        </div>

        {isOpenVide && (
          <div className="videoPlayer">
            <div
              className="closer"
              role="presentation"
              onClick={() => setIsOpenVide(false)}
            ></div>
            <div className="videoPlayer__video">
              <video src={video} controls autoPlay></video>
              <Icon name="Close" onClick={() => setIsOpenVide(false)} />
            </div>
          </div>
        )}
        <figure className="background" data-aos="fade-up" data-aos-delay="200">
          <img
            className="position-absolute top-100 start-50 translate-middle"
            src="/dist/assets/images/svg/pattern-lg-light.svg"
            alt="https://researchai.co"
          />
        </figure>
      </div>
      <img className='hero__m__image' src={Image} alt="" />
    </div>
  );
};

export default Hero;
