import React, { useState } from "react";
import "./index.scss";
import PricingSwitcher from "../PricingSwitcher";
import PricingStandartPlan from "../PricingStandartPlan";
import PricingPremiumPlan from "../PricingPremiumPlan";

const PricingHero = () => {
  const [isSwitched, setIsSwitched] = useState(false);
  return (
    <div className="flex pricing__hero">
      <div className="pricing__hero__content">
        <div className="title">
          Get Started With <span>ResearchAI</span>
        </div>
        <div className="subtitle">
          Take All the Difficulty & Stress Out of Writing <br />
          Influential Marketing Content!
        </div>
        <PricingSwitcher
          isSwitched={isSwitched}
          setIsSwitched={setIsSwitched}
        />
        {!isSwitched ? (
          <div className="pricing__plans__flex">
            <PricingStandartPlan
              type="Free"
              price="FREE"
              subText="per month"
            />
            <PricingStandartPlan
              type="Standard"
              price="$29.99"
              subText="per month"
            />
            <PricingPremiumPlan
              subText="per month"
              type={isSwitched ? "yearly" : "monthly"}
            />
          </div>
        ) : (
          <div className="pricing__plans__flex">
            <PricingStandartPlan
              type="Free"
              price="FREE"
              subText="per 1 month with annual payment"
            />
            <PricingStandartPlan
              type="Standard"
              price="$19.00"
              subText="per 1 month with annual payment"
              oldPrice="$23.99"
            />
            <PricingPremiumPlan
              type={isSwitched ? "yearly" : "monthly"}
              subText="per 1 month with annual payment"
            />
          </div>
        )}
      </div>
      <figure className="background" data-aos="fade-up" data-aos-delay="200">
        <img
          className="position-absolute top-100 start-50 translate-middle"
          src="/dist/assets/images/svg/pattern-lg-light.svg"
          alt="https://researchai.co"
        />
      </figure>
    </div>
  );
};

PricingHero.propTypes = {};

export default PricingHero;
