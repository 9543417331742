import React, { useRef } from "react";
import "./index.scss";
import { uniqueId } from "lodash";
import Icon from "components/common/Icon";
import { Link } from "react-router-dom";


const PricingStandartPlan = ({ period, oldPrice, price, subText, type }) => {
  const StarterPlan = [
    `${type === 'Standard' ? '20.000 word limit' : '2.000 word limit'}`,
    "Unlimited projects",
    "Email support",
    "+35 Templates",
    "+25 Languages",
  ];
  const text = useRef({
    Standard: "Good for Up to 3 Users",
    Free: "Good for Up to 1 User",
  });
  return (
    <div className="pricing__standart">
      <div className="pricing__standart__top">
        <div className="pricing__standart__type">{type} Plan</div>
        <div className="pricing__standart__typeText">{text.current[type]}</div>
      </div>
      <div className="pricing__standart__divider"></div>
      <div className="pricing__standart__middle">
        {oldPrice && <div className="pricing__standart__old">{oldPrice}</div>}
        <div className="pricing__standart__price">{price}</div>
        <div className="pricing__standart__price__text">{subText}</div>
      </div>
      <div className="pricing__standart__divider"></div>
      <div className="pricing__standart__functions">
        {StarterPlan.map((item) => {
          return (
            <div className="pricing__standart__function" key={uniqueId()}>
              <Icon name="Function" />
              {item}
            </div>
          );
        })}
      </div>
      <Link
        className="btn-main"
        to="/register"
        style={{ width: "100%", marginTop: "auto" }}
      >
        Start Writing
      </Link>
    </div>
  );
};

PricingStandartPlan.propTypes = {};

export default PricingStandartPlan;
